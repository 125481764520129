<template>
  <base-popup
      :popup-model="show"
      :title="formTitle"
      :valid="!valid || isSiteValid"
      :width="56"
      :overlay="overlay"
      @onCancelButtonClick="closePopupHandler"
      @onSaveButtonClick="saveSiteHandler"
  >
    <v-form
        :ref="formRef"
        v-model="valid"
        lazy-validation
    >
      <v-container class="mt-5">
        <v-row>
          <v-col
              cols="12"
              sm="12"
              md="4"
              class="pb-0"
          >
            <p>Enter Domains or URL* (Each Domain must be on separate line)</p>
            <v-textarea
                solo
                name="input-7-4"
                placeholder="Please enter valid URL(s)"
                clearable
                auto-grow
                v-model="currentItem.urls"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-popup>
</template>
<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";
import _ from "lodash";

export default {
  name: 'IndexerPopup',
  components: {BasePopup},
  mixins: [PopupMixin],
  data() {
    return {}
  },
  computed: {
    formTitle() {
      return this.isEditMode
          ? 'Edit Indexer'
          : 'Add Indexer'
    },
  },
  methods: {
    saveSiteHandler() {
      if (!this.isFormValid) {
        return;
      }
      if(!this.currentItem.urls) return;
      let urls = _.filter(this.urls.split("\n"));
      this.$emit('saveItemHandler', { urls });
      this.closePopupHandler();
    },
  }
}
</script>

<style lang="scss">
.site-field .v-messages__message{
  color: red;
}
</style>