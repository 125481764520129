<template>
<g-card page-title="Indexer" page-icon="mdi-power-plug">
  <g-list-page
      :columns="columns"
      :headers="headers"
      sort="created_at"
      :descending="true"
      stateendpoint="indexer.indexers"
      :actions="actions"
      @loadData="loadData"
      @editItem="editItem"
      @deleteItem="deleteItem"
      @destroyItem="destroyItem"
  >

  </g-list-page>
  <component
      is="SitePopup"
      ref="component"
      :basic-data="basicData"
      :item="editedItem"
      :show="showPopup"
      @closePopupHandler="closePopupHandler"
      @onPopupShow="openPopUp"
      @saveItemHandler="saveItemHandler"
  />
  <confirm-popup
      :show="showConfirm"
      confirm-event="onConfirmItem"
      @closePopupHandler="showConfirm = false"
      @onConfirmItem="confirmEvent(currentSelectedItem, true)"
  >
    <div>
      <!-- eslint-disable-next-line  -->
      <span>{{ confirmTxt }}</span>
    </div>
  </confirm-popup>
</g-card>
</template>

<script>
import GCard from "../../components/GCard";
import GListPage from "../../components/list/GListPage";
import ConfirmPopup from "../../components/popups/ConfirmPopup";
import ListMixins from "../../components/mixins/list/List";
import IndexerPopup from "../../components/popups/IndexerPopup";
export default {
  name: "IndexerPage",
  components: {ConfirmPopup, GListPage, GCard, IndexerPopup},
  mixins: [ListMixins],
  data() {
    return {
      columns: [],
      actions: {
        load: 'indexer/load',
        store: 'indexer/store',
        destroy: 'indexer/destroy'
      },
      headers: [
        {
          text: 'URL',
          value: 'url'
        },
        {
          text: 'Creation Date',
          value: 'created_at'
        },
        {
          text: 'Last Check Date',
          value: 'updated_at'
        },
        {
          text: 'Domain Status',
          align: 'center',
          value: 'is_domain_indexed'
        },
        {
          text: 'URL Status',
          align: 'center',
          value: 'is_url_indexed'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      basicData: {
        urls: "",
      }
    }
  }
}
</script>

<style scoped>

</style>